
import { useAccount } from "wagmi";
import BridgeImg from "./../images/bridgefull.gif";
import BridgeContainer from './bridge/BridgeContainer';

export default function Bridge() {
  const { address } = useAccount();

  return (
    <>
    <img className="w-full pt-20" src={BridgeImg} alt="bridge" />
    {address ? <BridgeContainer/>: <div className="text-octo-green text-center italic" ><p>Must be connected to bridge</p></div>}
    </>
  );
}