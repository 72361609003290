import { useState, useEffect } from "react";
import {pinkzAddress, inkzAddress} from './../Config';
import PinkzLogo from "./../../images/pinkz.gif"
import InkzLogo from "./../../images/inkz.gif"
import Spinner from './../spinners/Spinner';

export default function PinkzBalanceCheck({inkzBalance, pinkzBalance}) {
  const [loadingInkz, setLoadingInkz] = useState(true)
  const [loadingPinkz, setLoadingPinkz] = useState(true)

  useEffect(() => {
    if(inkzBalance) {
      setLoadingInkz(false)
    }
  }, [inkzBalance])
  useEffect(() => {
    if(pinkzBalance) {
      setLoadingPinkz(false)
    }
  }, [pinkzBalance])
  

  return (
    <div>
      <div className="flex items-center">
        <img src={InkzLogo} alt="inkslogo" className="w-7 mx-2 cursor-pointer"/>
        {loadingInkz ? <div className="bg-octo-red rounded-full"><Spinner /></div> : <p className="text-sm">{inkzBalance}</p>}
      </div>
      <div className="flex items-center">
          <img src={PinkzLogo} alt="pinkslogo" className="w-7 mx-2 cursor-pointer "/>
        {loadingPinkz ? <div className="bg-octo-red rounded-full"><Spinner /></div> : <p className="text-sm">{pinkzBalance}</p>}
      </div>
    </div>
  );
}