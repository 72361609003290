import React from "react";
import FAQs from "./../images/faqs2.gif";

export default function Faqs() {
  return (
    <>
    <img className="pt-24 w-full" src={FAQs} alt="FAQs" />
          <div className="bg-octo-blk faq-container mx-auto text-center px-20 text-octo-green">
            <h1 className="text-3xl mb-10">Getting Started</h1>
            <div id="FAQ" className="text-sm mb-10">
              <h2 className="text-2xl mb-4 text-octo-brightred">
                Bridging to Polygon
              </h2>
              <h4>In order to bridge from INKz to PINKz</h4>
              <h4>Click the Bridge tab in the navigation bar at the top.</h4>
              <h4>Make sure you are on the Ethereum network.</h4>
              <h4>
                Enter the amount you would like to transfer and click the pink
                Bridge button.
              </h4>
              <h4>
                The bridging proccess takes about 2 minutes, but in rare cases
                can take up to 15 minutes.
              </h4>
              <h4>
                If you have any issues bridging please open a ticket in our
                discord.
              </h4>
            </div>
            <div className="text-sm box-small mb-4">
              <h2 className="text-2xl mb-4 text-octo-brightred">
                The Auction House
              </h2>
              <h4>Each bid adds to the final price.</h4>
              <h4>
                When the timer falls below 5 minutes each bid will reset the
                timer to 5 minutes.
              </h4>
              <h4>
                When the auction is over, the highest bidder is the winner of
                the auction.
              </h4>
              <h4>The winner must pay the final price to claim the prize.</h4>
            </div>
            <div className="text-sm box-small">
              <h2 className="text-2xl mb-4 text-octo-brightred">
                The Marketplace
              </h2>
              <h4>
                To purchase a whitelist spot, first make sure you have the
                required PINKz on the Polygon network to purchase.
              </h4>
              <h4>Simply click purchase and complete the transaction.</h4>
              <h4>
                You are only allowed to purchase 1 whitelist spot per wallet!
              </h4>
              <h4>
                In order to be sure your whitelist was purchased successfully
                the purchase button should turn grey and read Already
                Purchased
              </h4>
            </div>
          </div>
    </>
  )
}
