import { Link } from "react-router-dom";
import MobileBridgeImg from '../../images/mobilebridge.gif'
import MobileFaqsImg from '../../images/mobilefaqs.gif'
import MobileShopImg from '../../images/mobileshop.gif'

export default function Home() {
  return (
    <div className="pt-20 w-full h-full bg-octo-blktored">
    <Link to="/bridge">
        <img className="w-full" src={MobileBridgeImg} alt="MobileBridgeImg"/>
      </Link>
      <Link to="/market">
        <img className="w-full" src={MobileShopImg} alt="MobileShopImg"/>
      </Link>
      <Link to="/faqs">
        <img className="w-full" src={MobileFaqsImg} alt="MobileFaqsImg"/>
      </Link>
    </div>
  )
}
