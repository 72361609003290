import { BACKEND_ADDR } from './../components/Config';

export const signOut = async () => {
    const res = await fetch(`${BACKEND_ADDR}/signout`, {
        method: "POST",
        credentials: 'include'
    });
    console.log(await res.text());
}
export const getInkzBalance = async () => {
    const res = await fetch(`${BACKEND_ADDR}/inkzbalance`, {
        credentials: 'include',
    });
    if(res.status == 200){
        const bal = await res.text()
      return bal
    }
}
export const getPinkzBalance = async () => {
    const res = await fetch(`${BACKEND_ADDR}/pinkzbalance`, {
        credentials: 'include',
    });
    if(res.status == 200){
        const bal = await res.text()
      return bal
    }
}