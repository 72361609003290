import React from 'react'
import MarketplaceImg from "./../images/marketplace.gif";
import { useAccount, useConnect, useDisconnect, useEnsName } from "wagmi";
import AuctionContainer from './auction/AuctionContainer';
import WhitelistContainer from './whitelist/WhitelistContainer';

export default function Market() {
  const { isConnected } = useAccount();
  return (
    <>
    <img className="w-full" src={MarketplaceImg} alt="market" />
    {!isConnected && <div className="bg-octo-red p-10 text-center text-m"><p className="text-octo-green">Connect wallet to view marketplace</p></div>}
    {/* {isConnected && <AuctionContainer />} */}
    {isConnected && <WhitelistContainer />}
    </>
  )
}
