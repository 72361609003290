import './App.css';
import {Route, Routes} from 'react-router-dom'
import { WagmiConfig, createClient } from 'wagmi'
import { mainnet, polygon, goerli, polygonMumbai} from 'wagmi/chains';
import { configureChains } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { getDefaultProvider } from 'ethers'
import { InjectedConnector } from 'wagmi/connectors/injected'
import Home from './components/main/Home';
import Market from './components/Market';
import Bridge from './components/Bridge';
import Faqs from './components/Faqs';
import Header from './components/main/Header';

import {useState, useEffect} from 'react'
import Footer from './components/main/Footer';
import Admin from './components/Admin';
import { BACKEND_ADDR } from './components/Config';

const { chains, provider } = configureChains(
  [mainnet, polygon, goerli, polygonMumbai],
  [publicProvider()]
)

const client = createClient({
  autoConnect: true,
  connectors: [new InjectedConnector({ chains })],
  provider,
})
function App() {
  const [backendData, setBackendData] = useState([{}])

  useEffect(() => {
    fetch(`${BACKEND_ADDR}/api`).then(
      response => response.json()
    ).then(
      data => {
        setBackendData(data)
      }
    )
  }, [])
  //console.log(backendData)
  return (
    <WagmiConfig client={client}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/market" element={<Market/>} />
        <Route path="/bridge" element={<Bridge/>} />
        <Route path="/faqs" element={<Faqs/>} />
        <Route path="/admin" element={<Admin/>} />
      </Routes>
      <Footer />
    </WagmiConfig>
  );
}

export default App;
