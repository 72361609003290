import {useRef, useState} from 'react'
import ReactDom from 'react-dom'
import { HiMenu } from "react-icons/hi";

export default function Modal({fOrderList}) {
    const [showModal, setShowModal] = useState(false)

    const openModal = () => {
        setShowModal(!showModal)
    }
  return (
    <div>
        <button className="mb-4 text-3xl" onClick={openModal}><HiMenu /></button>
        {showModal ? 
        <div onClick={openModal} className="bg-octo-blue mb-2 modal text-octo-green shadow-xl">
            <p className="pb-4">Purchase List</p>
            <p className="small-text">{fOrderList}</p>
        </div> 
        : null}
    </div>
  )
}