import React from 'react'

export default function Footer() {
  return (
    <div className="mt-10">
    <div className="footer">
      <h3 className="text-octo-green p-4 bg-octo-blue">© 2022 OctoHedz Labs</h3>
    </div>
    </div>
  )
}
