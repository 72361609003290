import {useState, useEffect} from 'react'
import PinkzLogo from "./../../images/pinkz.gif"
import { ethers } from 'ethers';
import Countdown from 'react-countdown';
import {useAccount, usePrepareContractWrite, useContractWrite, useWaitForTransaction, useNetwork} from "wagmi"
import WhitelistABI from "./../../abi/on-chain-whitelist-with-get-all-abi.json";
import { useIsMounted } from "./../../hooks/useIsMounted";
import Modal from './../modals/Modal';
import Spinner from './../spinners/Spinner';
import { SiLetsencrypt } from "react-icons/si";
import RevealModal from './../modals/RevealModal';
import { mainnet, polygon, goerli, polygonMumbai } from 'wagmi/chains'

export default function WhitelistCard({id,allPurchases,endTime,image,isBurn,name,price,slots,startTime,whitelistAddress, BACKEND_ADDR}) {
  const mounted = useIsMounted();
  const {address, isConnected} = useAccount()
  const [error, setError] = useState(null)
  const [status, setStatus] = useState(null)
  const [dataServed, setDataServed] = useState("")
  var currentTimeInMilliseconds=Date.now();
  const sOrderList = allPurchases.toString()
  const fOrderList = sOrderList.split(",").join("\n")
  const purchased = allPurchases.includes(address)
  const concluded = currentTimeInMilliseconds > (endTime * 1000)
  const started = currentTimeInMilliseconds > (startTime * 1000)
  const indexOfPurchase = allPurchases.indexOf(address)
  const { chain, chains } = useNetwork()
  const correctChain = chain?.id === polygon.id
  
  

  const {config, error: prepareError,isError: isPrepareError} = usePrepareContractWrite({
    address: whitelistAddress,
    abi: [
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "_whitelistId",
            "type": "uint256"
          }
        ],
        "name": "buyWhitelist",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
      },
    ],
    functionName: 'buyWhitelist',
    enabled: !purchased && !concluded && started && correctChain,
    args: [parseInt(id)],
  })
  const {
    data, 
    error: writeError, 
    isError, 
    isLoading: isPurchaseLoading, 
    isSuccess: isPurchaseStarted, 
    write 
  } = useContractWrite(config)

  const { isLoading: isPurchaseConfiming, isSuccess: txSuccess } = useWaitForTransaction({
    hash: data?.hash,
  })

  async function verifyPurchase() {
    let idstring = (id - 1).toString()
    const res = await fetch(`${BACKEND_ADDR}/verifypurchase`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idstring }),
        credentials: 'include'
    });
    const response = await res.text()
    const jsonResponse = JSON.parse(response)
    setDataServed(jsonResponse[0]?.message)
  }
   

  const isPurchased = txSuccess
  return (
    <>
      {mounted ? isConnected &&
    <div className={`card whitelist-card ${purchased ? "purchased-glow" : ""}`}>
        <div className="relative">
        <img src={image} alt={name} className='whitelist-image'/>
        {purchased && !dataServed && <div className="purchased-item cursor-pointer">
          <p className="bg-octo-green text-xl text-center text-octo-blue mt-3" onClick={verifyPurchase}>Click to Claim!</p>
        </div>}
        {purchased && dataServed && <RevealModal key={id} index={id} dataServed={dataServed} />}
        </div>
        <div className='card'>

            <div className='card content-item'>
            <p className="text-base">{name}</p>
            </div>
            {started ? <>
            <div className='card address-card'>
                <p>Price</p>
            </div>
            <div className='card address flex items-baseline'>
              <img src={PinkzLogo} className='w-8 mr-2' alt="inkz"/> <p>{ethers.utils.formatUnits(price, 18)} </p>
            </div>
            <div className='card address-card text-center'>
              <p>Remaining</p>
            </div>
            <div className='card address text-center'>
              <p>{slots - allPurchases.length}</p>
            </div></> : <div className="text-center bg-octo-brightred my-10 py-8"><p className="text-xl" >Coming Soon!</p></div>}
            <div className="flex flex-col text-center">
            {started && (currentTimeInMilliseconds < endTime * 1000)&& (<div>
                <p><Countdown className='counter' date={endTime * 1000}/></p>
            </div>)}
            {!started && <div><p className="text-xl my-8" ><Countdown className='counter' date={(startTime * 1000)}/></p></div>}
            {started && concluded && <div className='text'><p>Sale Concluded</p></div>}
            
            {(!allPurchases.includes(address) && currentTimeInMilliseconds < endTime * 1000 && started) ? (
              <button 
                disabled={!correctChain || isPurchaseLoading || isPurchaseStarted}
                data-purchase-loading={isPurchaseLoading}
                data-purchase-started={isPurchaseStarted}
                onClick={() => write()} 
                className='purchase-button'
                >
                  {!correctChain && <><p className="text-base pr-2">Wrong Network</p><p className="text-sm pr-2">Switch to polygon</p></>}
                  {isPurchaseLoading && (
                  <div className="flex items-center content-center justify-center">
                    <p className="text-base pr-2">Awaiting</p>
                    <Spinner />
                  </div>)}
                  {isPurchaseConfiming && (<div className="flex items-center content-center justify-center">
                    <p className="text-base pr-2">Confirming</p>
                    <Spinner />
                  </div>)}
                  {isPurchased && (<p className="text-base">Successful!</p>)}
                  {!isPurchaseLoading && !isPurchaseStarted && !isPurchased && correctChain && (<p className="text-base">Purchase</p>)}
                </button>
            ) : (null)}
            </div>
            {error ? (
            <div className='status'>
              <p className="text-xs text-red-400">
                {error}
              </p>
              </div>
            ) : null}
            {status ? (
                <div className='status'>
              <p className="text-xs text-green-400">
                {status}
              </p>
              </div>
            ) : null}
            {allPurchases.length !== 0 && (
              <div className="purchase-list text-octo-green">
              {<Modal key={id} index={id} fOrderList={fOrderList} />}
            </div>
            )} 
        </div>
    </div> : null}
  </>
  )
}
