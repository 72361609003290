import { useState } from "react";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsName,
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useNetwork,
} from "wagmi";
import { mainnet, polygon, goerli, polygonMumbai } from "wagmi/chains";
import Spinner from './../spinners/Spinner';
import { whitelistAddress, auctionAddress } from './../Config';
import { BigNumber } from 'ethers';

const OwnerPanel = () => {
  const [auctionName, setAuctionName] = useState("");
  const [auctionImage, setAuctionImage] = useState("");
  const [auctionStartTime, setAuctionStartTime] = useState("");
  const [auctionEndTime, setAuctionEndTime] = useState("");
  const [bidIncrement, setBidIncrement] = useState("");
  const [bidTimeExtension, setBidTimeExtension] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [isBurn, setIsBurn] = useState("");

  const [whitelistName, setWhitelistName] = useState("");
  const [whitelistImage, setWhitellistImage] = useState("");
  const [whitelistSlots, setWhitelistSlots] = useState("");
  const [whitelistStartTime, setWhitelistStartTime] = useState("");
  const [whitelistEndTime, setWhitelistEndTime] = useState("");
  const [whitelistPrice, setWhitelistPrice] = useState("");

  const startAuction = +new Date(auctionStartTime);
  const endAuction = +new Date(auctionEndTime);
  const startWhitelist = +new Date(whitelistStartTime);
  const endWhitelist = +new Date(whitelistEndTime);

  // BigNumber.set({EXPONENTIAL_AT: 25});
  // const value = BigNumber.from('1000000000000000000'); 
 

  const [buttonText, setButtonText] = useState("Public Key");
  // auction prepare
  const {
    config: auctionConfig,
    error: auctionPrepareError,
    isError: isAuctionPrepareError,
  } = usePrepareContractWrite({
    address: auctionAddress,
    abi: [
      {
        inputs: [
          {
            internalType: "string",
            name: "_name",
            type: "string",
          },
          {
            internalType: "string",
            name: "_image",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "_auctionStartTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_auctionEndTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_bidIncrement",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_bidTimeExtension",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "_startPrice",
            type: "uint256",
          },
        ],
        name: "createAuction",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    functionName: "createAuction",
    chainId: polygon.id,
    enabled:
      auctionName &&
      auctionImage &&
      startAuction &&
      endAuction &&
      bidIncrement &&
      bidTimeExtension &&
      startingPrice,
    args: [
      auctionName,
      auctionImage,
      startAuction / 1000,
      endAuction / 1000,
      (bidIncrement * 1000000000000000000).toLocaleString('fullwide', {useGrouping:false}),
      (bidTimeExtension * 60).toString(),
      (startingPrice * 1000000000000000000).toLocaleString('fullwide', {useGrouping:false}),
    ],
  });

  const {
    data: auctionData,
    error: auctionWriteError,
    isError: auctionIsError,
    isLoading: isAuctionLoading,
    isSuccess: isAuctionStarted,
    write: writeAuction,
  } = useContractWrite(auctionConfig);

  const { isLoading: AuctionIsLoading, isSuccess: AuctionTxSuccess } =
    useWaitForTransaction({
      hash: auctionData?.hash,
    });

  const isAuctionSetup = AuctionTxSuccess;

  const {
    config: whitelistConfig,
    error: whitelistPrepareError,
    isError: isWhitelistPrepareError,
  } = usePrepareContractWrite({
    address: whitelistAddress,
    abi: [
      {
        inputs: [
          {
            internalType: "string",
            name: "_name",
            type: "string",
          },
          {
            internalType: "string",
            name: "_image",
            type: "string",
          },
          {
            internalType: "uint32",
            name: "_startTime",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "_endTime",
            type: "uint32",
          },
          {
            internalType: "uint32",
            name: "_slots",
            type: "uint32",
          },
          {
            internalType: "uint256",
            name: "_price",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "_isBurn",
            type: "bool",
          },
        ],
        name: "startWhitelist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    functionName: "startWhitelist",
    chainId: polygon.id,
    enabled:
      whitelistName &&
      whitelistImage &&
      startWhitelist &&
      endWhitelist &&
      whitelistSlots &&
      whitelistPrice &&
      isBurn,
    args: [
      whitelistName,
      whitelistImage,
      startWhitelist / 1000,
      endWhitelist / 1000,
      whitelistSlots,
      (whitelistPrice * 1000000000000000000).toLocaleString('fullwide', {useGrouping:false}), //InputForm
      isBurn,
    ],
  });


  //      (whitelistPrice * 1000000000000000000).toString(),
  const {
    data: whitelistData,
    error: whitelistWriteError,
    isError: whitelistIsError,
    isLoading: isWhitelistLoading,
    isSuccess: isWhitelistStarted,
    write: writeWhitelist,
  } = useContractWrite(whitelistConfig);

  const { isLoading: WhitelistIsLoading, isSuccess: WhitelistTxSuccess } =
    useWaitForTransaction({
      hash: whitelistData?.hash,
    });

  const isWhitelistSetup = WhitelistTxSuccess;

  return (
    <>
      <div className="text-octo-brightred text-center py-10">
        <div className="text-3xl">
          <h1>Admin Panel</h1>
        </div>
      </div>
      <div className="flex flex-wrap justify-center">
        <div className="setup m-4">
          <h1 className="pb-4 text-xl text-octo-green">Setup Auction</h1>
          <form
            className="flex flex-col text-octo-green"
            onSubmit={(e) => {
              e.preventDefault();
              writeAuction?.();
            }}
          >
            <label className="format-form">
              <p>Item name</p>
            </label>
            <input
              className="input-textfield"
              type="text"
              placeholder="OctoHedz #888..."
              required
              value={auctionName}
              onChange={(e) => setAuctionName(e.target.value)}
            />
            <label className="format-form">
              <p>Image URL</p>
            </label>
            <input
              className="input-textfield"
              type="text"
              placeholder="https://imag..."
              required
              value={auctionImage}
              onChange={(e) => setAuctionImage(e.target.value)}
            />
            <label className="format-form">
              <p>Starting price</p>
            </label>
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={startingPrice}
              required
              onChange={(e) => setStartingPrice(e.target.value)}
            />
            <label className="format-form">
              <p>Start time</p>
            </label>
            <input
              className="input-textfield"
              type="datetime-local"
              value={auctionStartTime}
              required
              onChange={(e) => setAuctionStartTime(e.target.value)}
            />
            <label className="format-form">
              <p>End time</p>
            </label>
            <input
              className="input-textfield"
              type="datetime-local"
              value={auctionEndTime}
              required
              onChange={(e) => setAuctionEndTime(e.target.value)}
            />
            <label className="format-form">
              <p>Bid increment</p>
            </label>
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={bidIncrement}
              required
              onChange={(e) => setBidIncrement(e.target.value)}
            />
            <label className="format-form">
              <p>Grace period in minutes</p>
            </label>
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={bidTimeExtension}
              required
              onChange={(e) => setBidTimeExtension(e.target.value)}
            />
            <button className="input-btn-panel" type="submit">
              <p>Create Auction</p>
            </button>
          </form>
        </div>

        <div className="setup m-4">
          <h1 className="pb-4 text-xl text-octo-green">Setup Market</h1>
          <form
            className="flex flex-col text-octo-green"
            onSubmit={(e) => {
              e.preventDefault();
              writeWhitelist?.();
            }}
          >
            <label className="format-form">
              <p>Item name</p>
            </label>
            <input
              className="input-textfield"
              type="text"
              placeholder="OctoHedz"
              required
              value={whitelistName}
              onChange={(e) => setWhitelistName(e.target.value)}
            />
            <label className="format-form">
              <p>Image URL</p>
            </label>
            <input
              className="input-textfield"
              type="text"
              placeholder="https://imag..."
              required
              value={whitelistImage}
              onChange={(e) => setWhitellistImage(e.target.value)}
            />
            <label className="format-form">
              <p> Start time</p>
            </label>
            <input
              className="input-textfield"
              type="datetime-local"
              value={whitelistStartTime}
              required
              onChange={(e) => setWhitelistStartTime(e.target.value)}
            />
            <label className="format-form">
              <p>End time</p>
            </label>
            <input
              className="input-textfield"
              type="datetime-local"
              value={whitelistEndTime}
              required
              onChange={(e) => setWhitelistEndTime(e.target.value)}
            />
            <label className="format-form">
              <p>Number of spots available</p>
            </label>
            <input
              className="input-textfield"
              type="number"
              min="1"
              value={whitelistSlots}
              required
              onChange={(e) => setWhitelistSlots(e.target.value)}
            />
            <label className="format-form">
              <p>Purchase price</p>
            </label>
            <input
              className="input-textfield"
              type="number"
              min="0"
              value={whitelistPrice}
              required
              onChange={(e) => setWhitelistPrice(e.target.value)}
            />
            <label className="format-form">
              <p>Burn tokens?</p>
            </label>
            <input
              className="input-textfield"
              type="text"
              placeholder="true/false (no caps)"
              maxLength="5"
              value={isBurn}
              required
              onChange={(e) => setIsBurn(e.target.value)}
            />
            <button className="input-btn-panel" type="submit">
                {isWhitelistLoading && (<div className="flex items-center content-center justify-center"><p>Awaiting</p><Spinner /></div>)}
                {WhitelistIsLoading && (<div className="flex items-center content-center justify-center"><p>Confirming</p><Spinner /></div>)}
                {isWhitelistSetup && (<div className="flex items-center content-center justify-center"><p>Successful!</p></div>)}
                {!isWhitelistLoading && !isWhitelistStarted && !isWhitelistSetup && (<><p>Create Sale</p></>)}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default OwnerPanel;
